#editUserForm {
  display: grid;
  grid-template-columns: repeat(2, minmax(100px, 200px) minmax(200px, 1fr));
  gap: 0.2rem;
}

#permissionsDiv {
  grid-column: span 2;
  margin-top: 1.5rem;
  line-height: 1.5;
}

#editUserForm .buttonDiv {
  grid-column: span 4;
  gap: 5px;
  display: flex;
  flex-direction: column;
  justify-self: center;
  text-align: center;
  margin-top: 2rem;
}

#editUserForm button {
  width: 200px;
}

.permissionLabel {
  font-size: 13px;
  opacity: 0.9;
}
